.admin-nav {
    height: 100vh !important;
    border-right: 1px solid #E7EAF0;

    img {
        width: 70%;
    }
}

.body-home {
    width: 100%;
    background-color: #F9FAFB;

    header {
        background: white;
        padding: 40px !important;
    }

    .card{
       box-shadow:  0 3px 3px -1px rgb(10 22 70 / 10%), 0 0 1px 0 rgb(10 22 70 / 6%);
    }

    .btn {
        width: 10% !important;
    }

    table {
        // background-color: white;
        margin: 0 10px;
    }

}
